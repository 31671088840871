/* stylelint-disable font-family-no-missing-generic-family-keyword */

.statusContainer {
    width: 280px;
    box-sizing: border-box;
    padding: 24px;
    background-color: #1D2023;
    border-radius: 20px;
    color: white;
    position: absolute;
    top:  50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.status, .numberCard {
    display: flex;
    flex-direction: column;
}

.numberCard span:first-child {
    color: #969FA8;
    font-family: "MTS Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.numberCard span:nth-child(2) {
    color: #FFF;
    font-family: "MTS Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.status {
    margin-top: 20px;
}

.status span:first-child {
    color: #969FA8;
    font-family: "MTS Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.status span:nth-child(2) {
    font-family: "MTS Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.status span:nth-child(3) {
    margin-top: 12px;
    color: #FFF;
    font-family: "MTS Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.active {
    margin-top: 2px;
    color: #74DF8B;
}

.notActive {
    margin-top: 2px;
    color: #FA8A64;
}

@media (min-width: 360px) {
    .statusContainer {
        width: 320px;
    }
}

@media (min-width: 768px) {
    .statusContainer {
        width: 448px;
        padding: 32px;
    }

    .numberCard span:first-child {
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
    }

    .numberCard span:nth-child(2) {
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
    }

    .status {
        margin-top: 24px;
    }

    .status span:first-child {
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
    }

    .status span:nth-child(2) {
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
    }

    .status span:nth-child(3) {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }
}
