body, main {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0; 
    margin: 0;
}

.active {
    background: no-repeat url(../../images/backgroundMobile.png);
    background-position: 50% 0;
    background-size: cover;
}

.notActive {
    background: no-repeat url(../../images/backgroundMobileNotActive.png);
    background-position: 50% 0;
    background-size: cover;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 20px;
}

.page {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media(min-width: 768px) {
    header {
        margin-top: 32px;
        padding: 0 40px;
    }

    .active {
        background: no-repeat url(../../images/background.png);
        background-position: 50% 0;
        background-size: cover;
    }
    
    .notActive {
        background: no-repeat url(../../images/backroundNotActive.png);
        background-position: 50% 0;
        background-size: cover;
    }
}