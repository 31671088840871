/* stylelint-disable font-family-no-missing-generic-family-keyword */

.moreInfo p {
    margin: 0;
    font-family: "MTS Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.moreInfo a {
    color: #007CFF;
    text-decoration: none;
}

.contacts {
    display: flex;
    align-items: center;
    margin-top: 22px;
}

.support {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.support span:first-child {
    font-size: 14px;
    line-height: 20px;
}

.support a {
    color: #007CFF;
    font-family: "MTS Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media (min-width: 768px) {
    .contacts {
        margin-top: 26px;
    }

    .moreInfo p {
        line-height: 28px;
        font-size: 20px;
    }
}