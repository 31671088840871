/* stylelint-disable selector-class-pattern */

.spinner {
    display: inline-flex;
}

.s {
    --spinner-size: 16px;
}

.m {
    --spinner-size: 1.5rem;
}

.l {
    --spinner-size: 2.5rem;
}

.spinner svg {
    width: var(--spinner-size);
    height: var(--spinner-size);
    animation: spinner 1s linear infinite;
}

@keyframes spinner {
    to { transform: rotate(360deg); }
}

.primary {
    fill: #969FA8;
}

.white {
    fill: #969FA8;
}

.negative {
    fill: #969FA8;
}

.mtsRed {
    fill: #969FA8;
}